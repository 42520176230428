import { type NextPage } from 'next';
import Image from 'next/image';

import { Button, Layout, Title1 } from '~/components/designSystem';
import { Body1 } from '~/components/designSystem/Typography';
import landing from '../../public/landing.png';

const Index: NextPage = () => {
  return (
    <>
      <Layout showSidebar={false}>
        <div>
          <div className="relative w-screen flex flex-col justify-center items-center px-8">
            <div className="mx-auto mt-24 flex flex-col gap-16 md:mx-0 md:w-2/5">
              <div className="flex flex-col gap-3">
                <Title1>Flexpa Portal</Title1>
                <Body1>
                  Flexpa Portal is an interface designed for developers to manage their settings for the Flexpa
                  platform. Developers can generate test and live mode application keys, provision team access, and
                  maintain business and billing details.
                </Body1>
              </div>
              <div className="flex flex-col gap-3 text-flexpa-gray-primary-60">
                {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                <a href="/login">
                  <Button>Login to Portal</Button>
                </a>
                <Body1>
                  No account yet? Check if your organization does. If so, please request to be added as a team member.
                </Body1>
              </div>
            </div>
            <div className="w-screen mt-16 md:static md:w-1/2 md:mt-24 flex justify-center px-8">
              <Image className="w-full" src={landing} alt="" />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Index;
